<template>
  <v-container id="dashboard" class="pt-1 pb-1" fluid tag="section">
    <v-row v-if="appType == 'ebib'">
      <!-- <v-col cols="12">
        <base-material-card color="success" inline title="Ebibliophil" class="px-5 py-3" />
      </v-col> -->
      <v-col cols="12" sm="6" lg="3">
        <base-material-stats-card
          color="info"
          class="mb-0"
          icon="mdi-account-group"
          :title="$t('Cititori')"
          :value="readers"
          sub-icon=""
          sub-text=""
        />
      </v-col>
      <v-col cols="12" sm="6" lg="3">
        <base-material-stats-card
          color="success"
          class="mb-0"
          icon="mdi-book-open-variant"
          :title="$t('Titluri')"
          :value="titles"
          sub-icon=""
          sub-text=""
        />
      </v-col>
      <v-col cols="12" sm="6" lg="3">
        <base-material-stats-card
          color="orange"
          class="mb-0"
          icon="mdi-text-box-multiple-outline"
          :title="$t('Inventare')"
          :value="inventories"
          sub-icon=""
          sub-icon-color=""
          sub-text=""
        />
      </v-col>
      <v-col cols="12" sm="6" lg="3">
        <base-material-stats-card
          color="indigo"
          class="mb-0"
          icon="mdi-bank"
          :title="$t('Autorități')"
          :value="authorities"
          sub-icon=""
          sub-icon-color=""
          sub-text=""
        />
      </v-col>
      <v-col cols="12" sm="6" lg="3">
        <base-material-stats-card
          color="teal"
          class="mb-0"
          icon="mdi-swap-horizontal"
          :title="$t('Împrumuturi')"
          :value="loans"
          sub-icon=""
          sub-text=""
        />
      </v-col>
      <v-col cols="12" sm="6" lg="3">
        <base-material-stats-card
          color="teal"
          class="mb-0"
          icon="mdi-swap-horizontal"
          :title="$t('Istoric împrumuturi')"
          :value="loansHistory"
          sub-icon=""
          sub-text=""
        />
      </v-col>
      <v-col cols="12" sm="6" lg="3">
        <base-material-stats-card
          color="lime"
          class="mb-0"
          icon="mdi-google-keep"
          :title="$t('Rezervări')"
          :value="reservations"
          sub-icon=""
          sub-text=""
        />
      </v-col>
      <v-col cols="12" sm="6" lg="3">
        <base-material-stats-card
          color="lime"
          class="mb-0"
          icon="mdi-google-keep"
          :title="$t('Istoric rezervări')"
          :value="reservationsHistory"
          sub-icon=""
          sub-text=""
        />
      </v-col>
    </v-row>
    <v-row>
      <template v-for="itm in menu">
        <v-col v-if="itm.items" :key="itm.path" cols="12" sm="6" md="4" lg="3">
          <base-material-card
            inline
            class="mb-0 fill-height"
            :icon="itm.icon ? itm.icon : 'mdi-clipboard-text'"
            :title="itm.title"
          >
            <v-list>
              <v-list-item v-for="sub in itm.items" :key="sub.key" :to="itm.path + '/' + sub.path">
                <v-list-item-icon>
                  <v-icon v-text="sub.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ sub.title }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </base-material-card>
        </v-col>
        <v-col v-else-if="itm.path" :key="itm.path" cols="12" sm="6" md="4" lg="3">
          <base-material-card
            inline
            class="mb-0 fill-height"
            :icon="itm.icon ? itm.icon : 'mdi-clipboard-text'"
            :title="itm.title"
          >
            <v-list>
              <v-list-item :to="itm.path">
                <v-list-item-icon>
                  <v-icon v-text="itm.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ itm.title }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </base-material-card>
        </v-col>
      </template>
    </v-row>
  </v-container>
</template>
<script>
import axios from "../../plugins/axios"
export default {
  name: "DashboardDashboard",
  components: {},
  data() {
    return {
      path: "",
      // menu: [],
      readers: "",
      loans: "",
      loansHistory: "",
      reservations: "",
      reservationsHistory: "",
      titles: "",
      inventories: "",
      authorities: ""
    }
  },

  computed: {
    companyId() {
      return this.$store.getters.companyId
    },
    appType() {
      return this.$store.getters.appType
    },
    accountMenu() {
      return this.$store.getters.accountMenu
    },
    accountType() {
      return this.$store.getters.accountLevel
    },
    menu() {
      if (this.$store.getters.accountMenuDefault) {
        return this.$store.getters.accountMenuDefault
      }
      return []
    }
  },
  watch: {
    $route(to) {
      //this.$log("route to ", to)
      if (to.path == this.path) {
        this.reloadData()
      }
    }
  },
  created() {
    this.path = this.$route.path
    this.reloadData()
    this.$log("Drawer appmenu")
    // axios
    //   .get(
    //     'app_menu?where={"owner.app_type":"' +
    //       this.appType +
    //       '","menu_type":"' +
    //       this.accountMenu +
    //       '","owner.user_type":"' +
    //       this.accountType +
    //       '"}'
    //   )
    //   .then(response => {
    //     //this.$log("menumenumenu ", response.data._items[0].content)
    //     this.menu = response.data._items[0].content
    //   })
  },
  methods: {
    reloadData() {
      if (this.appType == "ebib") {
        axios.get("dashboard/view_libraries_counters/" + this.companyId).then(res => {
          this.$log("dashboard new ", res)
          this.readers = String(res.data.data[0].records.cln)
          this.titles = String(res.data.data[0].records.mon)
          this.inventories = String(res.data.data[0].records.inv)
          this.authorities = String(res.data.data[0].records.ath)
          this.loans = String(res.data.data[0].records.ebib_lib_loan)
          this.loansHistory = String(res.data.data[0].records.ebib_lib_loan_history)
          this.reservations = String(res.data.data[0].records.ebib_lib_reserve)
          this.reservationsHistory = String(res.data.data[0].records.ebib_lib_reserve_history)
        })
      }
    }
  }
}
</script>
<style></style>
